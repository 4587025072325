import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { store } from 'src/redux/store';
import { purgeSingleState } from "../redux/slices/single";

// ----------------------------------------------------------------------

const purgeSingle = () => store.dispatch(purgeSingleState());

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  layout: DashboardLayout,
  routes: [
    // LISTING
    // ----------------------------------------------------------------------    
    {
      exact: true,
      path: PATH_APP.app.listing,
      component: lazy(() => import('src/views/Listing'))
    },
    // SINGLE
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.single,
      component: lazy(() => import('src/views/Single'))
    },   
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.general.dashboard,
      component: lazy(() => import('src/views/DashboardAppView'))
    },
    {
      exact: true,
      path: PATH_APP.general.ecommerce,
      component: lazy(() => import('src/views/DashboardEcommerceView'))
    },
    {
      exact: true,
      path: PATH_APP.general.analytics,
      component: lazy(() => import('src/views/DashboardAnalyticsView'))
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },
    // SETTING
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.app.root,
      component: () => <Redirect to={PATH_APP.general.root} />
    },
    {
      exact: true,
      path: PATH_APP.app.tracking,
      component: lazy(() => import('src/views/Tracking'))
    },
    {
      exact: true,
      path: PATH_APP.app.filemanager ,
      component: lazy(() => import('src/views/FileManager'))
    },
    {
      exact: true,
      path: PATH_APP.app.filemanagercsv ,
      component: lazy(() => import('src/views/FileManager/Csv'))
    },
    {
      exact: true,
      path: PATH_APP.app.filemanagerphp ,
      component: lazy(() => import('src/views/FileManager/Php'))
    },   
    // WEARHOUSE
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.warehouse.import,
    //   component: lazy(() => import('src/views/Import'))
    // },
    // {
    //   exact: true,
    //   path: PATH_APP.warehouse.search,
    //   component: lazy(() => import('src/views/Search'))
    // },    
    // APP
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.item.post,
    //   component: lazy(() => import('src/views/Item'))
    // },  
    {
      exact: true,
      path: PATH_APP.app.profile,
      component: lazy(() => import('src/views/Profile'))
    },    
    {
      exact: true,
      path: PATH_APP.app.importexport,
      component: lazy(() => import('src/views/Importexport'))
    },
    {
      exact: true,
      path: PATH_APP.app.importstorelocator,
      component: lazy(() => import('src/views/Importexport'))
    },
    {
      exact: true,
      path: PATH_APP.app.forms,
      component: lazy(() => import('src/views/Forms'))
    },
    {
      exact: true,
      path: PATH_APP.app.formssettingauth,
      component: lazy(() => import('src/views/Forms/Auth'))
    },
    {
      exact: true,
      path: PATH_APP.app.formssettingsmtp,
      component: lazy(() => import('src/views/Forms/Smtp/global.js'))
    },
    {
      exact: true,
      path: PATH_APP.app.formssettingcsv,
      component: lazy(() => import('src/views/Forms/Csv'))
    },
    {
      exact: true,
      path: PATH_APP.app.formssettingmailchimp,
      component: lazy(() => import('src/views/Forms/Mailchimp'))
    },
    {
      exact: true,
      path: PATH_APP.app.visualblock,
      component: lazy(() => import('src/views/VisualBlockSingle'))
    },
    {
      exact: true,
      path: PATH_APP.productitem.post,
      component: lazy(() => import('src/views/ProductItem'))
    },
    {
      exact: true,
      path: PATH_APP.app.caching,
      component: lazy(() => import('src/views/Cache'))
    },                   
    {
      exact: true,
      path: PATH_APP.app.formssettingmailchimppost,
      component: lazy(() => import('src/views/Forms/Mailchimp/Single'))
    },
    {
      exact: true,
      path: PATH_APP.app.sitemapimages,
      component: lazy(() => import('src/views/Sitemapimages'))
    },
    // ----------------------------------------------------------------------
    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default AppRoutes;